<template>
  <div>
    <!--form add & update university-->
    <ek-dialog
      btnText="جامعة جديدة"
      ref="dialog"
      :isEdit="!!universityDto.id"
      :title="universityDto.id ? 'تعديل  جامعة' : 'اضافة جامعة '"
      @delete="deleteUni(universityDto.id)"
      placeholder="ابحث عن جامعة محددة"
      @ok="submitForm"
      @close="resetForm"
      @search="setSearch"
    >
      <template #body>
        <validationObserver ref="dashUserForm">
          <ek-input-text
            v-model="universityDto.name"
            name="اسم "
            placeholder="ادخل اسم الجامعة"
            label="اسم الجامعة"
            :rules="[{ type: 'required', message: 'اسم الجامعة مطلوب' }]"
          ></ek-input-text>

          <template v-if="universityDto.id && universityDto.facultyIds.length">
            <label>الكليات</label>
            <b-list-group>
              <b-list-group-item v-for="item in universityDto.facultyIds" :key="item">
                {{ facultyName.find((f) => f.id == item).name }}
              </b-list-group-item>
            </b-list-group>
          </template>

          <label class="mt-2">صورة الجامعة</label>

          <ek-input-image accept="image/png, image/jpeg" name="img" :image="universityDto.imageUrl == ''" @input="uploadFile($event)" ref="fileInput">
            <h5>اسحب الملف او انقر للتحميل</h5>
          </ek-input-image>
          <div v-if="universityDto.id">

              <!-- <img :style="!item.url ? 'object-fit: contain' : ''" 
              :src="item.url ? $store.getters['app/domainHost'] + '/' + item.url: appName"  /> -->
            
            
            
            <img 
            class="w-100 h-100 mt-2"
            :src="universityDto.imageUrl ? $store.getters['app/domainHost'] + '/' + universityDto.imageUrl: appName"
          /></div>
        </validationObserver>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      appName: $themeConfig.app.appName,
    }
  },
  computed: {
    ...mapState({
      universityDto: (state) => state.university.universityDto,
      isDialogOpen: (state) => state.university.isDialogOpen,
      facultyName: (state) => state.faculty.facultyName,
      imageUrl: (state) => state.university.imageUrl,
    }),
    ...mapGetters(["getImageUrl"]),
  },
  methods: {
    ...mapActions(["addUniversity", "updateUniversity", "addFile"]),
    //submit add & update university
    submitForm() {
      this.$refs.dashUserForm.validate().then((suc) => {
        if (suc) {
          if (this.universityDto.id) {
            this.updateUniversity(this.universityDto);
            this.$refs.dialog.close();
          } else {
            this.addUniversity({ ...this.universityDto, imageUrl: this.imageUrl })
              .then((data) => {
                console.log(data);
                this.$refs.dialog.close();
              })
              .catch(() => {});
          }
        }
      });
    },
    deleteUni(id) {
      this.$store.dispatch("deleteUniversities", [id]);
    },

    //upload img university
    uploadFile(event) {
      if (event && event.file) {
        this.addFile({ file: event.file, folderName: "Profile" }).then((res) => {
          this.universityDto.imageUrl = res.data;
        });
      }
    },
    resetForm() {
      this.$refs.dashUserForm.reset();
      this.$refs.fileInput.reset();
      this.$store.commit("SET_UNIVERSITY_DTO");
      this.$store.commit("IS_DIALOG_OPEN", false);
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "facultiesCount", "studentsCount"],
        query,
      });
    },
  },
  watch: {
    isDialogOpen(nv) {
      if (nv) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown.b-dropdown {
  right: 0 !important;
}
</style>
